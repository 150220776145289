.head-primary-content{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 59px;
    color: #121714;
}
.head-secondary-content{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #121714;
    opacity: 0.5;
}