* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}

.stepImageAppScreen {
	max-width: 526px !important;
	max-height: 858px !important;
	width: 100% !important;
	height: 100% !important;
}

.stepImageUPI {
	max-width: 526px !important;
	max-height: 858px !important;
	width: 100% !important;
	height: 100% !important;
}

.head-hero {
	max-width: 654px !important;
	max-height: 577px !important;
	width: 100% !important;
	height: 100% !important;
}

.rowSpace {
	margin-top: 90px;
}

.largeRowSpace {
	margin-top: 150px;
}


/* Small screen */

@media only screen and (max-width: 550px) {
	.mobile-row-space {
		margin-top: 20px !important;
	}
	.d-flex,
	.d-flex-btn {
		display: flex!important;
		flex-direction: column !important;
		align-items: center !important;
		text-align: center !important;
	}
	.col-reverse {
		flex-direction: column-reverse !important;
	}
	.head-primary-content {
		font-size: 33px !important;
	}
	.head-secondary-content {
		font-size: 16px !important;
	}
	.infoCard-content {
		padding: 40px 20px 40px 20px !important;
	}
	.downloadAppCard-content {
		padding: 40px 20px 40px 20px !important;
	}
	.primaryText {
		font-family: Montserrat;
		font-style: normal;
		font-weight: bold;
		font-size: 26px !important;
		line-height: 39px;
		color: #000000;
	}
	.primaryText-subtext {
		font-family: Montserrat;
		font-style: normal;
		font-weight: bold;
		font-size: 26px !important;
		line-height: 39px;
		color: rgba(18, 23, 21, 0.05);
	}
	.head-hero {
		margin-top: 30px !important
	}
	.stepImageAppScreen {
		position: relative !important;
		left: -30px !important;
	}
	.footer {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
}


/* Medium screen */

@media only screen and (min-width: 551px) and (max-width: 992px) {
	.mobile-row-space {
		margin-top: 30px !important;
	}
	.d-flex {
		display: flex!important;
		flex-direction: column !important;
		align-items: center !important;
		text-align: center !important;
	}
	.col-reverse {
		flex-direction: column-reverse !important;
	}
	.head-primary-content {
		font-family: Montserrat;
		font-style: normal !important;
		font-weight: bold !important;
		font-size: 40px !important;
		line-height: 59px !important;
		color: #121714 !important;
	}
	.infoCard-content {
		padding: 40px 20px 40px 20px !important;
	}
	.downloadAppCard-content {
		padding: 40px 20px 40px 20px !important;
	}
	.primaryText {
		font-family: Montserrat;
		font-style: normal;
		font-weight: bold;
		font-size: 26px !important;
		line-height: 39px;
		color: #000000;
	}
	.primaryText-subtext {
		font-family: Montserrat;
		font-style: normal;
		font-weight: bold;
		font-size: 26px !important;
		line-height: 39px;
		color: rgba(18, 23, 21, 0.05);
	}
	.stepImageAppScreen {
		position: relative !important;
		left: -30px !important;
	}
	.head-hero {
		margin-top: 30px !important;
		max-width: 500px !important;
		max-height: 500px !important;
		width: 100% !important;
		height: 100% !important;
	}
	.footer {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
}