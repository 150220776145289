.reviewText{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #121715;
}
.reviewerName{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}


.ft-slick__dots--custom{
    width: 12px;
    height: 12px;
    background: #121715;
    opacity: 0.1;
    border-radius: 100%;
}
.slick-active .ft-slick__dots--custom{
    width: 12px;
    height: 12px;
    background: #179B55;
    opacity: 1;
    border-radius: 100%;
}


blockquote {
    position: relative;
    max-width: 730px;
    width:100%;
    display: block;
    position: relative;
    padding-top: 30px;
}
blockquote:after {
    content: attr(data-bg-text);
    position: absolute;
    top: -53px;
    left: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 150px;
    opacity: 0.05;
    color: #000000;
    height: 225px;
    width: 75px;
}