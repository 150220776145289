.modal-header{
    border: none !important;
    padding: 0px !important;
}
.contactus-head{
    font-size: 32px;
    font-weight: 700;
    color: rgba(18, 23, 21,1) !important;
    margin-bottom: 25px !important;
}
.modal-content{
    width: 90%;
    margin: auto;
    padding-left: 40px !important;
    padding-right: 40px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    text-align: left !important;
}
.modal-body{
    padding: 1rem !important;
}
.form-group{
    margin-bottom: 20px !important;
}
.form-group>input{
    font-weight: 500 !important;
    font-size: 14px !important;
    border: none;
    border-radius: 4px !important;
    background-color:#EEEEEE;
    height: 51px !important;
}
.form-group>textarea{
    font-weight: 500 !important;
    font-size: 14px !important;
    border: none;
    border-radius: 4px !important;
    background-color: #EEEEEE;
    height: 127px !important;
}
.input-label{
    font-weight: 500 !important;
    color: rgba(18, 23, 21, 0.5) !important;
    font-size: 12px !important;
    margin-bottom: 10px !important;
}
.error-label{
    font-weight: 500 !important;
    color: red !important;
    font-size: 12px !important;
    margin-bottom: 10px !important;
}
.send-msg-btn,.send-msg-btn:hover{
    font-weight: 500 !important;
    font-size: 16px !important;
    background-color: #179F57;
    color: #ffffff;
    margin: auto;
    width: 167px;
    height: 47px;
}
.spinner-border{
    margin-right: 10px !important;
}
.contactus-success{
        font-size: 40px;
        font-weight: 200;
        color: rgba(18, 23, 21,1) !important;
        margin-bottom: 10px !important;
}
.contactus-body-success{
    font-size: 20px;
    font-weight: 200;
    color: rgba(18, 23, 21,1) !important;
    margin-bottom: 25px !important; 
}

.close{
    opacity: 1 !important;
}

.send-msg-btn{
    margin-top: 10px !important;
    margin-bottom: 15px !important;
}